export function isChanged(a: any, b: any) {
	for (const key in a) {
		// console.log(key, a, b);
		if (a[key] !== b[key]) {
			return true;
		}
	}

	return false;
}
