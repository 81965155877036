import {
	ActivatedSvg,
	ArrowBackSvg,
	DownloadSvg,
	CloseRedSvg,
	TotalSvg,
	SearchPeopleSvg,
} from 'assets/svg-icon';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';
import './CodesInfo.scss';
import { CodesInfoHeader } from './CodesInfoHeader/CodesInfoHeader';
import { CodesInfoBody } from './CodesInfoBody/CodesInfoBody';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import { useDispatch, useSelector } from 'react-redux';
import {
	botPromoCodeAllRequest,
	botPromoCodeRequest,
	botPromoCodeRevokeRequest,
	botPromoCodeSuccess,
} from 'redux/reducers/ai-chat-bot/reducer';

import {
	getBotPromoCodeInfoAllSelector,
	getBotPromoCodeInfoSelector,
	getCodeLoaderSelector,
} from 'redux/reducers/ai-chat-bot/selectors';
import { handleDownload } from 'untils/xlsx';
import { Pagination } from 'ui/Pagination';
import { Loader } from 'components/Loader';

export const CodesInfo: FC = () => {
	const params = useParams();
	const [popup, setPopup] = useState<string>('');
	const [userId, setUserId] = useState<string>('');
	const [dataFiltter, setDataFiltter] = useState<IDataFiltter>({});
	const dispatch = useDispatch();
	const botPromoCodeInfo = useSelector(getBotPromoCodeInfoSelector);
	const botPromoCodeAllInfo = useSelector(getBotPromoCodeInfoAllSelector);
	const getCodeLoader = useSelector(getCodeLoaderSelector);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const navigation = useNavigate();

	const submitFilter = (value: number) => {
		setCurrentPage(value);
	};

	const onClose = () => {
		setUserId('');
		setPopup('');
	};

	const onRevokeAll = () => {
		dispatch(
			botPromoCodeRevokeRequest({
				navigation,
				onClose,
				body: {
					all: true,
					code_id: Number(params.id),
				},
			}),
		);
	};

	const onRevoke = () => {
		dispatch(
			botPromoCodeRevokeRequest({
				onClose,
				body: {
					code: popup || '',
					[Number(popup) ? 'user_id' : 'empty']: Number(popup) ? Number(userId) : true,
				},
			}),
		);
	};

	const onPopup = (event: MouseEvent) => {
		const { name, id } = event.currentTarget as HTMLButtonElement;
		setUserId(id);
		setPopup(name);
	};

	useEffect(() => {
		dispatch(
			botPromoCodeRequest({
				current_page: currentPage,
				per_page: 25,
				...dataFiltter,
				code_id: Number(params.id),
			}),
		);

		return () => {
			dispatch(botPromoCodeSuccess(null));
		};
	}, [currentPage, dataFiltter]);

	useEffect(() => {
		console.log(botPromoCodeInfo);
		botPromoCodeInfo &&
			dispatch(
				botPromoCodeAllRequest({
					current_page: 1,
					per_page: botPromoCodeInfo.total,
					code_id: Number(params.id),
				}),
			);
	}, [botPromoCodeInfo]);

	return (
		<div className="main-content">
			<div className="title-block-wrap">
				<div className="title-block">
					<Link
						className="back-button"
						to={`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`}
					>
						<ArrowBackSvg />
					</Link>
					<p className="title big-first-letter">{`${params.name}`}</p>
				</div>
			</div>
			<div className="table-block">
				<div className="codes">
					<div className="codes__info">
						<div className="codes__info-box">
							<div className="codes__info-inner">
								<TotalSvg />
								<div className="codes__info-wrap">
									<div className="codes__info-text">Total</div>
									<div className="codes__info-count">{botPromoCodeInfo?.count_codes_all || 0}</div>
								</div>
							</div>
						</div>
						<div className="codes__info-box">
							<div className="codes__info-inner">
								<ActivatedSvg />
								<div className="codes__info-wrap">
									<div className="codes__info-text">Activated</div>
									<div className="codes__info-count">{botPromoCodeInfo?.count_codes_used || 0}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="codes__buttons">
						{botPromoCodeInfo?.list.length ? (
							<button
								onClick={() => handleDownload(botPromoCodeAllInfo?.list)}
								style={{
									display: 'flex',
									gap: '5px',
									background: '#fff',
									color: '#0A49A5',
									border: '1px solid #CFCFD2',
								}}
								className="add-btn"
								type="button"
							>
								<DownloadSvg />
								Export Excel
							</button>
						) : (
							<div></div>
						)}

						<button name="remove-all" onClick={onPopup} type="button" className="codes__revoce">
							<CloseRedSvg />
							<span className="codes__revoce-text">Revoke all codes</span>
						</button>
					</div>
				</div>
				{botPromoCodeInfo?.list.length && !getCodeLoader ? (
					<div className="table table--info-codes">
						<CodesInfoHeader setDataFiltter={setDataFiltter} dataFiltter={dataFiltter} />
						<div className="table-body">
							{botPromoCodeInfo?.list ? (
								botPromoCodeInfo?.list?.map((bot, index) => (
									<CodesInfoBody key={index} onPopup={onPopup} botInfo={bot} />
								))
							) : (
								<div className="wrapper-not-found">
									<SearchPeopleSvg />
									<p>We could not find the information. Try again later.</p>
								</div>
							)}
						</div>
					</div>
				) : (
					<Loader />
				)}
			</div>

			{popup ? (
				<PopUpAsk
					title={`Are you sure you want to revoke ${popup === 'remove-all' ? 'all' : ''} code${
						popup === 'remove-all' ? 's' : ''
					}?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={popup === 'remove-all' ? onRevokeAll : onRevoke}
					setText={setPopup}
				/>
			) : null}

			{botPromoCodeInfo?.list.length && Number(botPromoCodeInfo.total) > 25 ? (
				<Pagination
					request={submitFilter}
					currentPage={currentPage}
					numberPage={Math.ceil(botPromoCodeInfo.total / 25)}
				/>
			) : null}
		</div>
	);
};
