import { IDeleteRequest } from 'redux/reducers/channels/types';

export const endpoint = {
	auth: {
		LOGIN: '/auth/login',
		FORGOT: '/auth/forgot_password',
		GET_QR: '/auth/otp/generate',
		ENABLE_TWO_FA: '/auth/otp/verify',
		RESET_PASS: '/auth/reset_password',
		CREATE_PASS: '/auth/complete_registration',
		REFRESH: '/auth/refresh_token',
		LOGOUT: '/auth/logout',
	},
	channels: {
		CHANNELS_LIST: '/channels/list',
		CHANNELS_RECOMMEND_TOP_LIST: '/channels/recommend_top_list',
		CHANNELS_RECOMMEND_ADD_TOP: '/channels/recommend_add_top',
		CHANNELS_RECOMMEND_REMOVE_TOP: '/channels/recommend_remove_top',
		RECOMMEND_MOVE_TOP: '/channels/recommend_move_top',
		STATISTIC: '/channels/statistic',
		RECOMMEND_CHANNELS: `/channels/recommend`,
		VERIFY_CHANNELS: `/channels/verify`,
		DEACTIVATE: '/channels/deactivate',
		DELETE_CHANNEL: (params: IDeleteRequest) =>
			`/channels/${params.channel_id}/delete${params.recover ? '?recover=true' : ''}`,
		DETAILS_CHANNEL: (id: string) => `/channels/${id}`,
		ADMINS: (id: string) => `/channels/${id}/admins`,
		MEMBERS: (id: string) => `/channels/${id}/members`,
		MEDIA_SALES: (id: string) => `/channels/${id}/media/sales`,
		POSTS: (id: string) => `/channels/${id}/posts`,
		POST_DETAILS: (channel_id: string, post_is: string) =>
			`/channels/${channel_id}/posts/${post_is}`,
		POST_COMENTS: (channel_id: string, post_is: string) =>
			`/channels/${channel_id}/posts/${post_is}/comments`,
		POST_MEDIA_FILE: (channel_id: string) => `/channels/${channel_id}/media/files`,
		GET_MEDIA_FILE: (channel_id: string, file_id: string) =>
			`/channels/${channel_id}/media/files/${file_id}`,
	},
	users: {
		USERS_LIST: '/users/list',
		USER: (user_id: string) => `/users/${user_id}`,
		USERS_VERIFY: `/users/verify`,
		BLOCK_USER: `/users/block`,
		DELETE_USER: `/users/delete`,
		CHANNELS_USER: (id: string) => `/users/${id}/channels`,
		GROUPS_USER: (id: string) => `/users/${id}/groups`,
		TRANSACTIONS_USER: (id: string) => `/users/${id}/transactions`,
		PHOTOS_USER: (id: string) => `/users/${id}/photos`,
		LIMIT: '/users/update_limit_channel',
	},
	admins: {
		ADMINS_LIST: '/admin/admins',
		ADMIN_INFO: '/admin/admin',
		CREATE_ADMINS: '/admin/add_admin',
		DELETE_ADMIN: '/admin/delete',
		CHANGE_ADMIN: '/admin/change_admin',
		ENABLE_OR_DESABLE_2FA: '/auth/otp/disable',
		GET_CSV: `admin/admins_csv`,
	},
	transactions: {
		WITHDRAWAL: '/dashboard/transactions',
		ADMIN_TREASURY: '/dashboard/transactions/admin-treasury',
		TRASACTION_APPROVE: (id: string) => `/dashboard/transactions/${id}/approve`,
		TRASACTION_REJECT: (id: string) => `/dashboard/transactions/${id}/reject`,
	},
	reports: {
		REPORTS_CHANNEL: '/reports/channels/list',
		REPORTS_POSTS: '/reports/channel_posts/list',
		REPORTS_GROUPS: '/reports/groups/list',
		REPORTS_MESSAGES: '/reports/messages_in_groups/list',
		GET_DETAILS: (channel_id: string) => `/reports/get/${channel_id}`,
		POST_LIST_PREVIOUS_MESSAGES: (complain_id: string) =>
			`/reports/list/groups_message/${complain_id}`,
		POST_LIST_PREVIOUS_GROUPS: (complain_id: string) => `/reports/list/groups/${complain_id}`,
		POST_LIST_PREVIOUS_CHANNEL_MESSAGES: (complain_id: string) =>
			`/reports/list/channels_message/${complain_id}`,
		POST_LIST_PREVIOUS_CHANNEL: (complain_id: string) => `/reports/list/channels/${complain_id}`,
		LIST_TYPES: '/reports/list_types',
		REJECT_MESSAGES: (complain_id: string) => `/reports/reject/${complain_id}`,
		DEACTIVATE_CHANNEL: (channel_id: string) => `/reports/deactivate_channel/${channel_id}`,
		DEACTIVATE_GROUP: (group_id: string) => `/reports/deactivate_group/${group_id}`,
		DEACTIVATE_MESSAGE: (message_id: string) => `/reports/deactivate_message/${message_id}`,
	},
	support: {
		CONNECTION: '/connection/websocket',
		EDIT: '/support/edit',
		EDIT_RESOLVE: '/support/edit_resolve',
		EDIT_RESOLVER: '/support/edit_resolver',
		EDIT_STATUS: '/support/edit_status',
		EDIT_TYPE: '/support/edit_type',
		GET_HISTORY: '/support/history',
		NOTES_ADD: '/support/notes/add',
		NOTES_DELETE: (id: string) => `/support/notes/delete/${id}`,
		NOTES_EDIT: '/support/notes/edit',
		NOTES_LIST: (id: string) => `/support/notes/list/${id}`,
		SUPPORT_SEND: '/support/send',
		SUBSCRIBE: '/support/subscribe',
		SUBSCRIBE_ALL: '/support/subscribe_all',
		SUBSCRIBE_SERVICE: '/support/subscribe_service',
		UNSUBSCRIBE: '/support/unsubscribe',
		UNSUBSCRIBE_ALL: '/support/unsubscribe_all',
		UNSUBSCRIBE_SERVICE: '/support/unsubscribe_service',
		UPLOAD: (channel_id: string, user_id: string) =>
			`/support/upload?channel_id=${channel_id}&user_id=${user_id}`,
		USERS_BY_COUNTRY: '/support/users_by_country',
		USERS_WHO_NEED_HELP: '/support/users',
		GET_TYPES: '/support/get_types',
		TICKETS: 'support/tickets',
	},
	aiServices: {
		GET_BAD_WORDS: '/bad_word/list',
		EDIT_BAD_WORDS: '/bad_word/edit',
		ADD_BAD_WORD: '/bad_word/add',
		DELETE_BAD_WORD: (id: string) => `/bad_word/delete/${id}`,
	},
	apkFile: {
		APK_UPLOAD: '/apk/upload',
		APK_LIST: '/apk/list',
		APK_DELETE: (id: string) => `/apk/delete/${id}`,
	},
	reserved: {
		GET_USERSNSME: '/reserved_usernames/list',
		EDIT_USERSNSME: '/reserved_usernames/update',
		ADD_USERSNSME: '/reserved_usernames/create',
		CHECK_USERSNSME: '/reserved_usernames/check',
		DELETE_USERSNSME: (id: string) => `/reserved_usernames/${id}`,
	},
	utils: {
		countries: '/utils/countries',
		photo: (hash: string) => `/storage/photo/${hash}`,
		document: (hash: string) => `/storage/document/${hash}`,
		video: (hash: string) => `/storage/video/${hash}`,
		uploads: (hash: string) => `/storage/upload/${hash}`,
	},
	finance: {
		COMMISSIONS: '/finance/commissions',
		TRANSACTIONS: '/finance/transactions',
		PAYMENT_LIMITS: '/finance/payment/limits',
		WITHDRAW_REQUEST: '/finance/withdraw/requests',
		WITHDRAW_REQUEST_UPDATE: '/finance/withdraw/requests/update',
		DEPOSIT_BALANCES: '/finance/statistic/balances/deposit',
		WITHDRAW_BALANCES: '/finance/statistic/balances/withdraw',
		TRANSACTIONS_GRAPH: '/finance/statistic/transactions/graph',
		WITHDRAW_REQUEST_ID: (id: string) => `/finance/withdraw/requests/${id}`,
		GET_PDF: (id: string) => `/finance/withdraw/request_pdf/${id}`,
	},
	referrals: {
		GET_GRAPHS: '/loyalty/graph',
		GET_GRAPH: (id: string) => `/loyalty/graph/${id}`,
		ADD_LOYALTY: '/loyalty/package/add',
		CHANGE_LOYALTY: '/loyalty/package/change',
		LOYALTY_LIST: '/loyalty/package/list',
		DELETE_LOYALTY: (id: string) => `/loyalty/package/${id}`,
		ADD_LOYALTY_USER: '/loyalty/gen',
		DELETE_LOYALTY_USER: (id: string) => `/loyalty/user_package/${id}`,
		GET_LOYALTY_USERS: '/loyalty/users',
		GET_LOYALTY_USERS_PARTNER: '/loyalty/user_partner',
		GET_SUMMA: '/loyalty/summa',
	},
	aiChatBots: {
		GET_LIST_FREE_CHAT_BOTS: '/aicontrol/bot_list_free',
		GET_LIST_CHAT_BOTS: '/aicontrol/bot_list',
		GET_BOT_INFO: (bot_id: string) => `/aicontrol/bot_info/${bot_id}`,
		GET_BOT_INFO_PRICE: () => `/aicontrol/bot_price_info`,
		EDIT_BOT: '/aicontrol/edit_bot',
		EDIT_BOT_PRICE: '/aicontrol/edit_bot_price',
		BOT_STATISTICS_LIST: '/aicontrol/bot_statistics_list',
		TOTAL_STATISTICS_LIST: '/aicontrol/total_statistic_list',
		GET_COMMANDS: (id: string) => `aicontrol/bot_command_info/${id}`,
		EDIT_COMMAND: '/aicontrol/edit_bot_command',
		GET_CATEGORY_LIST: '/aicontrol/bot_category_list',
		GET_BOT_DATA: (bot_id: string) => `/aicontrol/bot_user/${bot_id}`,
		GET_BUTTONS: (bot_id: string) => `/aicontrol/bot_buttons/${bot_id}`,
		GET_COMMAND_INFO: (bot_id: string) => `/aicontrol/bot_command_info/${bot_id}`,
		EDIT_COMMAND_INFO: '/aicontrol/edit_bot_command',
		EDIT_BUTTONS: '/aicontrol/edit_bot_buttons',
		EDIT_BOT_USER: '/aicontrol/edit_bot_user',
		ADD_NEW_BOT: '/aicontrol/add_new_bot',
		GET_RECOMMENDED: '/aicontrol/bot_user_recommended_list',
		UPLOAD_LOGO: (bot_id: string) => `/aicontrol/upload_logo/${bot_id}`,
		UPLOAD_BACKGROUND: (bot_id: string) => `/aicontrol/upload_background/${bot_id}`,
		EDIT_BOT_TURN_ON: '/aicontrol/edit_bot_turn_on',
		ADD_BOT_PROMO: '/aicontrol/add_bot_promo',
		BOT_PROMO_CODE: '/aicontrol/bot_promo_code',
		BOT_PROMO_CODE_REVOKE: '/aicontrol/bot_promo_code_revoke',
		BOT_PROMO_CODE_LIST: '/aicontrol/bot_promo_codes_list',
		REMOVE_LOGO_IMG: '/aicontrol/profile_image_remove',
		GET_LOGO_LIST: (bot_id: string) => `/aicontrol/profile_image_list/${bot_id}`,
		GET_TEMPLATE_TEXT: (bot_id: string) => `/aicontrol/bot_template_text_info/${bot_id}`,
		EDIT_TEMPLATE_TEXT: '/aicontrol/edit_bot_template_text',
		BOT_SRUCTURE_INFO: (bot_id: string) => `/aicontrol/bot_structure_info/${bot_id}`,
		EDIT_BOT_SRUCTURE_INFO: '/aicontrol/edit_bot_structure',
		BOT_TEMPLATE_PHOTO: (bot_id: string) => `/aicontrol/bot_template_photo_info/${bot_id}`,
		EDIT_BOT_TEMPLATE_PHOTO: '/aicontrol/edit_bot_template_photo',
		DELETE_BOT_USER: (bot_id: string) => `/aicontrol/delete_bot_user/${bot_id}`,
		GET_PARTNERS: '/aicontrol/bot_promo_codes_partners',

		ADD_ADVERTISE: (id: string) => `/aicontrol/add_advertise/${id}`,
		UPLOAD_ADVERTISE: (bot_id: string, id: string) =>
			`/aicontrol/upload_advertise_media/${bot_id}/${id}`,
		ADVERTISE_REMOVE: (bot_id: string, id: string) => `/aicontrol/advertise_remove/${bot_id}/${id}`,
		ADVERTISE_MEDIA_REMOVE: ({ bot_id, id, hash }: { bot_id: string; id: string; hash: string }) =>
			`/aicontrol/advertise_media_remove/${bot_id}/${id}/${hash}`,
		EDIT_ADVERTISE: '/aicontrol/edit_advertise',
		EDIT_ADVERTISE_FREQUENC: '/aicontrol/edit_advertise_frequency',
		GET_ADVERTISE_FREQUENC: (id: string) => `/aicontrol/advertise_frequency/${id}`,
		ADVERTISE_LIST: '/aicontrol/advertise_list',
		TIMEZONE_LIST: '/aicontrol/timezone_list',
		AI_MODEL_LIST: '/aicontrol/ai_model_list',
	},
	reactions: {
		GET_REACTIONS: '/reactions/list',
		GET_REACTION: (id: string) => `/reactions/get/${id}`,
		ADD_REACTION: '/reactions/add',
		EDIT_REACTION: '/reactions/edit',
		ACIVE_REACTION: '/reactions/on_off',
		DELETE_REACTION: (id: string) => `/reactions/delete/${id}`,
	},
};
